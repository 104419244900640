import { useEffect, useState } from 'react';
import auth from '../services/authService';
import { useStateValue } from '../context/StateProvider';
import { useAppDispatch } from '../redux/hooks';
import { setAdds, setHouses, setRooms, useAdds, useHouses, useRooms } from '../redux/adminSlice';

export default function useFetchOwnersData(dataType) {
  const [{ user }] = useStateValue();
  const dispatch = useAppDispatch();
  const houses = useHouses();
  const rooms = useRooms();
  const adds = useAdds();
  const [loadingHouseState, setLoadingHouseState] = useState(false);

  useEffect(() => {
    async function fetchHouses() {
      if (dataType === 'owner_houses') {
        await auth.getOwnerHouses(user?.houses).then((res) => {
          dispatch(setHouses(res.data));
          setLoadingHouseState(true);
        });
      } else if (dataType === 'owner_rooms') {
        await auth.getOwnerRoomies(user.houses).then((res) => {
          dispatch(setRooms(res.data));
          setLoadingHouseState(true);
        });
      } else if (dataType === 'neatkeepers') {
        await auth.getAllHouses().then((res) => {
          dispatch(setHouses(res.data));
          setLoadingHouseState(true);
        });
      } else if (dataType === 'empty_rooms') {
        await auth.getAllAds().then((res) => {
          dispatch(setAdds(res.data));
          setLoadingHouseState(true);
        });
      }
    }
    fetchHouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [dataType == 'owner_houses' ? houses : dataType === 'empty_rooms' ? adds : rooms, loadingHouseState];
}
