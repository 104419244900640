import React, { useState } from 'react';
import BedIcon from '@mui/icons-material/Bed';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Modal } from 'react-bootstrap';
import auth from '../../services/authService';
import { toast } from 'react-toastify';
import { useStateValue } from '../../context/StateProvider';
import { useAppDispatch } from '../../redux/hooks';
import { removeHouse } from '../../redux/adminSlice';

function HouseCard({ house }) {
  const history = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [{ user }] = useStateValue();
  const dispatch = useAppDispatch();

  const deleteHome = async () => {
    await auth.deleteHome(house._id, user._id).then(() => {
      toast.success('house removed!');
      dispatch(removeHouse({ houseId: house._id }));
    });
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div className="card mt-5 bg-light " data-animation="true">
        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
          <span className="d-block blur-shadow-image">
            <img src={house.url} alt="img-blur-shadow" className="img-fluid shadow border-radius-lg" />
          </span>
        </div>
        <div className="card-body pb-0">
          <div className="d-flex mt-n6 mx-auto">
            <div className="btn btn-link text-warning ms-auto border-0">
              <BedIcon onClick={() => history(`/house?id=${house._id}`)} />
            </div>
            <div className="btn btn-link text-info  border-0">
              <EditIcon onClick={() => history(`/edithouse?id=${house._id}`)} />
            </div>
            <div className="btn btn-link text-primary me-auto border-0">
              <DeleteIcon onClick={() => setOpenDeleteModal(true)} />
            </div>
          </div>
          <h5 className="font-weight-normal text-uppercase text-case text-center mt-3">
            <span>{house.house_name}</span>
          </h5>
          <hr className="bg-dark horizontal my-0" />
          <div className="pt-3 mb-0">
            <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
              <span className="mb-2 text-xs">
                CODE:
                <span className="text-dark font-weight-bold ms-sm-2">{house.house_code}</span>
              </span>
              <span className="mb-2 text-xs">
                WIFI:
                <span className="text-dark font-weight-bold ms-sm-2">{house.wifi_name}</span>
              </span>
              <span className="mb-0 text-xs">
                PASS:
                <span className="text-dark font-weight-bold ms-sm-2">{house.wifi_password}</span>
              </span>
            </div>
          </div>
        </div>
        <hr className="bg-dark horizontal my-0" />
        <div className="card-footer d-flex justify-content-center">
          <PlaceIcon className="position-relative text-lg me-2" />
          <p className="text-sm text-bold my-auto"> {house.address}</p>
        </div>
      </div>

      <Modal
        show={openDeleteModal}
        onHide={() => setOpenDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        className="fade"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete House</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you understand what you are doing? this will remove the selected Home, if you are sure, go ahead.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => deleteHome()}>
            DELETE Home
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  // ) : (
  //   <div className="card mt-5" data-animation="false">
  //     <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
  //       <span className="d-block blur-shadow-image">
  //         <img
  //           src="https://live.staticflickr.com/65535/51878644975_f73cc035cc_c.jpg"
  //           alt="img-blur-shadow"
  //           className="img-fluid shadow border-radius-lg"
  //         />
  //         <AddCircleIcon fontSize="large" className="mt-0" />
  //       </span>
  //     </div>
  //     <div className="card-body pb-3">
  //       <h5 className="font-weight-normal text-uppercase text-case text-center mt-3">
  //         <span>ADD HOUSE</span>
  //       </h5>
  //       <hr className="bg-dark horizontal my-0" />
  //       <div className="pt-3 mb-0"></div>
  //     </div>
  //     <hr className="bg-dark horizontal my-0" />
  //   </div>
  // );
}

export default HouseCard;
