import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { useAppSelector } from './hooks';

// Define a type for the slice state
export interface AppUser {
  email: string;
  name: string;
  uid: string;
}
export type AdminState = {
  user: AppUser | null;
  appState: 'loading' | 'loaded' | 'error' | 'init';
  houses: Array<any>;
  rooms: Array<any>;
  adds: Array<any>;
};
// Define the initial state using that type
const initialState = {
  user: null,
  appState: 'init',
  houses: [],
  rooms: [],
  adds: [],
} as AdminState;

export const adminSlice = createSlice({
  name: 'admin',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AppUser | null>) => {
      state.user = action.payload;
    },
    setHouses: (state, action: PayloadAction<any>) => {
      state.houses = action.payload;
    },
    setRooms: (state, action: PayloadAction<any>) => {
      state.rooms = action.payload;
    },
    setAdds: (state, action: PayloadAction<any>) => {
      state.adds = action.payload;
    },
    removeHouse: (state, action: PayloadAction<{ houseId: string }>) => {
      state.houses = state.houses.filter((house) => house._id !== action.payload.houseId);
    },
  },
});

export const { setUser, setHouses, setRooms, setAdds, removeHouse } = adminSlice.actions;

export const selectUser = (state: RootState) => {
  if (state.admin.user == null) throw new Error('User is not logged in');
  return state.admin.user;
};
export const useAppUser = () => {
  return useAppSelector(selectUser);
};
export const selectHouses = (state: RootState) => {
  return state.admin.houses;
};
export const useHouses = () => {
  return useAppSelector(selectHouses);
};
export const selectRooms = (state: RootState) => {
  return state.admin.rooms;
};
export const useRooms = () => {
  return useAppSelector(selectRooms);
};
export const selectAdds = (state: RootState) => {
  return state.admin.adds;
};
export const useAdds = () => {
  return useAppSelector(selectAdds);
};
export const selectHouse = (houseId: string) => (state: RootState) => {
  return state.admin.houses.find((house) => house._id === houseId);
};
export const useHouse = (args: { houseId: string }) => {
  return useAppSelector(selectHouse(args.houseId));
};

export default adminSlice.reducer;
