import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import usePagination from '../../hooks/usePagination.js';
import { useStateValue } from '../../context/StateProvider';
import { useRooms } from '../../redux/adminSlice';

function NewGuys({ setLink }) {
  const [roomiesList, setRoomiesList] = useState();
  const [loadingState, setLoadingState] = useState('loading');
  const tenantsList = useRooms();
  const [emptyRooms] = useState(tenantsList?.filter((tenant) => tenant.is_empty === true));
  const { Pagination, displayData } = usePagination({
    data: roomiesList,
    amountPerPage: 5,
  });

  const [open, setOpen] = useState(false);
  const [room, setRoom] = useState();
  const [{ user }] = useStateValue();

  const { register, handleSubmit, reset, setValue } = useForm({
    // resolver: joiResolver(schema),
  });

  const handleClickOpen = (roomie) => {
    setRoom(roomie);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onSubmit(data) {
    // Check if valid room data is provided
    if (data.room && data.room !== '-1') {
      auth
        .editRoomNew(room, data.room)
        .then(() => {
          toast.success('New Roomie Added!');
          setOpen(false);
        })
        .catch((err) => {
          // Provide feedback to the user
          // console.log(err.response);
          toast.error(err.response.data.message);
          setOpen(false);
        });
    } else {
      // Handle case when room data is not valid
      toast.warning('Please select a valid room.');
    }
  }

  const displayRoomies = displayData?.map((roomie) => (
    <tr key={roomie._id}>
      <td className="align-middle">{roomie.first_name}</td>
      <td className="align-middle">{roomie.last_name}</td>
      {roomie.house ? (
        <td className="align-middle">{roomie.house.house_name}</td>
      ) : (
        <td className="align-middle">House is deleted</td>
      )}
      <td className="align-middle">{roomie.room_name}</td>
      <td className="align-middle">{roomie.base_rent + roomie.utilities}</td>
      <td className="align-middle">{roomie.signed ? 'YES' : 'NO'}</td>
      <td className="align-middle">{roomie.start_date}</td>
      <td className="align-middle">{roomie.end_date}</td>
      <td className="align-middle">
        <div>
          <Button className="mr-2" onClick={() => printLink(roomie)} variant="warning">
            Get Link
          </Button>
        </div>
      </td>
      <td className="align-middle">
        <div>
          <Button className="" onClick={() => handleClickOpen(roomie)}>
            Add Roomie
          </Button>
        </div>
      </td>
    </tr>
  ));

  useEffect(() => {
    async function getAllRoomies() {
      await auth.getAllNewRoomies(user._id).then((res) => {
        setRoomiesList(res.data);
        setLoadingState('gotThem');
      });
    }

    getAllRoomies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printLink = (roomie) => {
    setLink(`http://sweepnsleep.com/newlease/${roomie._id}`);
  };

  return (
    <>
      <div className="card table-responsive p-0">
        {Pagination}
        <table className="table table-striped align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Last Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">House Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Roome Name</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">all-in-price</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Did Sign?</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Start Date</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">End Date</th>
              <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">Get Link</th>
            </tr>
          </thead>
          <tbody>
            {loadingState === 'loading' ? (
              <tr>
                <th>loading</th>
              </tr>
            ) : (
              displayRoomies
            )}
          </tbody>
        </table>
        <Modal show={open} onHide={handleClose} backdrop="static" keyboard={false} className="fade">
          <Modal.Header closeButton>
            <Modal.Title>Select a Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Make sure you mark the room vacant before adding the new tenant!
            <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myform">
              <Row className="mb-3">
                <Form.Group className="col mb-3" controlId="formGridType">
                  <Form.Label className="text-bold">Select Room</Form.Label>
                  <Form.Control
                    className="bg-light"
                    // defaultValue={!isAddMode ? editRoomie.house._id : null}
                    // value={!isAddMode ? editRoomie.house._id : null}
                    {...register('room')}
                    name="room"
                    as="select"
                    onChange={(e) => setValue('room', e.target.value)}
                  >
                    <option value={-1}>Select...</option>
                    {emptyRooms.map((room, i) => (
                      <option value={room._id} key={i}>
                        {room.room_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" form="myform">
              Add Roomie to Room
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default NewGuys;
