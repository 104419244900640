import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ScheduleSelector from 'react-schedule-selector/dist/lib/ScheduleSelector';
import GCard from '../utils/GCard';
import auth from '../../services/authService';

function AdView() {
  const addId = useLocation().search.slice(4);

  const [add, setAdd] = useState(null);
  useEffect(() => {
    async function fetchAdd() {
      const res = await auth.getAddById(addId);
      setAdd(res.data[0]);
    }
    fetchAdd();
  }, [addId]);
  return (
    // write a code that display all applicants that applies for the room ad use GCard component
    // to display the applicants
    <GCard header={'Applicants'}>
      <div className="row">
        {add?.applicants.map((applicant, i) => (
          <div className="col-12" key={'app-' + i}>
            <div className="card text-secondary bg-info m-2">
              <div className="card-body">
                <h5 className="card-title text-secondary">
                  {applicant.newRoomie.first_name} {applicant.newRoomie.last_name}
                </h5>

                <p className="card-text">{applicant.newRoomie.phone}</p>
                <p className="card-text">{applicant.newRoomie.email}</p>
                <p className="card-text">Start Date: {applicant.newRoomie.start_date}</p>

                <div className="m-2">
                  <ScheduleSelector
                    selection={applicant.schedule}
                    numDays={10}
                    minTime={8}
                    maxTime={22}
                    hourlyChunks={1}
                    dateFormat="ddd M/D"
                    selectionColor={'#f0f0f0'}
                    dayBackgroundColor={'#fff'}
                    dayTextColor={'#000'}
                  />
                </div>

                <div className="m-2">
                  <div className="card text-secondary  m-4">
                    <div className="card-body">
                      <h5 className="card-title text-secondary">{applicant.newRoomie.first_name}&apos;s Answers:</h5>
                      <h6>1. Are you willing to participate in weekly cleaning chores?</h6>
                      <p className="card-text ms-3">{applicant.questions.chores}</p>
                      <h6>
                        2. Are you okay with quiet nighttime from 10pm to 7am? (no voice must travel from your room to
                        other rooms, this includes long night conversations/phone calls, movies, music, etc).
                      </h6>
                      <p className="card-text ms-3">{applicant.questions.quiet_time}</p>
                      <h6>3. Do you smoke?</h6>
                      <p className="card-text ms-3">{applicant.questions.smoking}</p>
                      <h6>4. What do you do for a living?</h6>
                      <p className="card-text ms-3">{applicant.questions.job}</p>
                      <h6>
                        5. Is it okay with you that the neatkeeper/homeowner give you advices if he/she sees that you
                        are not following the rules correctly? You can request a rule change if you would like to change
                        the rule and admin will review it
                      </h6>
                      <p className="card-text ms-3">{applicant.questions.lead_ok}</p>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <button className="btn btn-success">Accept</button>
                  <button className="btn btn-danger">Reject</button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </GCard>
  );
}

export default AdView;
