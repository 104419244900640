import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import GCard from '../utils/GCard';
import Select from 'react-select';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { addMethod } from 'yup';
import DamageDepositReportButton from './DamageDeposit/DamageDepositReport';
import { useHouse } from '../../redux/adminSlice';

function EditHouse() {
  const history = useNavigate();
  const location = useLocation();
  const houseId = location.search.slice(4);
  const house = useHouse({ houseId });
  // House can be undefined if we're reloading the page. In that case, we load the house again
  const [houseBeingEdited, setHouseBeingEdited] = useState(house ?? null);
  useEffect(() => {
    async function fetchHouse() {
      await auth.getHouseById(houseId).then((res) => {
        setHouseBeingEdited(res.data);
      });
    }
    if (houseBeingEdited == null) {
      fetchHouse();
    }
  }, [houseId, houseBeingEdited]);
  const [roomiesList, setRoomiesList] = useState([]);
  const [choresList, setChoresList] = useState([]);
  const [bathrooms, setBathrooms] = useState(
    houseBeingEdited?.bathrooms != null && houseBeingEdited?.bathrooms.length > 0
      ? houseBeingEdited.bathrooms.map((b) => {
          return { name: b.name, roomies: b.roomies };
        })
      : [{ name: 'default', roomies: roomiesList.map((r) => r._id) }]
  );

  const [numberOfBathrooms, setNumberOfBathrooms] = useState(
    houseBeingEdited?.bathrooms != null && houseBeingEdited?.bathrooms.length > 0
      ? houseBeingEdited.bathrooms.length
      : 1
  );

  const schema = Joi.object({
    house_name: Joi.string().min(1).max(50).required(),
    address: Joi.string().min(1).max(50).required(),
    postal_code: Joi.string().min(1).max(10).required(),
    house_code: Joi.string().min(1).max(20).required(),
    wifi_name: Joi.string().min(1).max(20).required(),
    wifi_password: Joi.string().min(1).max(20).required(),
    welcome_message: Joi.string().min(1).max(50),
    utilities: Joi.number().required(),
    numTenants: Joi.number().required(),
    lead: Joi.string().min(1).max(50),
    landlord_signature: Joi.string().min(1).max(50),
    weekly_chores: Joi.array(),
    bathrooms: Joi.array(),
  }).options({ allowUnknown: true });

  const goToAddVariableCosts = () => {
    history(`/addvariablecosts/${houseId}`);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    resolver: joiResolver(schema),
  });
  useEffect(() => {
    async function fetchRoomieFromHouseList() {
      if (houseId != null)
        await auth.getRoomieFromHouse(houseId).then((res) => {
          setRoomiesList(res.data);
        });
    }
    fetchRoomieFromHouseList();
  }, [houseId]);
  useEffect(() => {
    async function getChores() {
      await auth.getAllChores().then((res) => {
        setChoresList(res.data);
      });
    }
    getChores();
  }, []);
  useEffect(() => {
    Object.keys(houseBeingEdited).forEach((field) => {
      setValue(field, houseBeingEdited[field]);
    });
    if (houseBeingEdited.should_rotate == null) {
      setValue('should_rotate', true);
    }
  }, [houseBeingEdited, setValue]);

  function getChoreList() {
    let choreList = [];
    houseBeingEdited?.weekly_chores_0.forEach((chore) => {
      choreList = [
        ...choreList,
        {
          chore: chore.chore,
          id: chore._id,
          who: chore.who ? chore.who._id : 'none',
          description: chore.description,
        },
      ];
    });
    return choreList;
  }
  // transform the chores to the format that react-select can understand

  function transformChores(chores) {
    const transformedArray = [];
    chores.forEach((item) => {
      item.chore.forEach((chore) => {
        transformedArray.push({
          label: chore.name,
          value: chore._id,
          who: item.who,
        });
      });
    });
    return transformedArray;
  }

  function transform2(chores) {
    // make a copy of the chores
    chores = [...chores];
    chores.forEach((element, index) => {
      chores[index] = { value: element._id, label: element.name };
    });
    return chores;
  }

  const onSubmit = (data) => {
    let previous_chores = getChoreList();
    let selected_chores = data.selected_chores;

    let updatedSelectedChores = [];

    Object.keys(selected_chores).forEach((who) => {
      let choreObjects;
      if (selected_chores[who] === undefined) {
        choreObjects = previous_chores.filter((chore) => chore.who === who)[0].chore;
      } else {
        const choreIds = selected_chores[who];
        choreObjects = choreIds.map((choreId) => choresList.find((chore) => chore._id === choreId));
      }
      updatedSelectedChores.push({
        who: who,
        chore: choreObjects,
      });
    });
    if (!data.best_roomie) {
      data.best_roomie = 0;
    }
    if (!data.worst_roomie) {
      data.worst_roomie = 0;
    }
    data = { ...data, weekly_chores_0: updatedSelectedChores };
    // remove selectred_chores from data
    delete data.selected_chores;

    data.numTenants = houseBeingEdited.numTenants;
    data.bathrooms = bathrooms;
    if (addMethod) {
      // removing id for add method issue in the backend
      delete data._id;
    }

    // removing __v, this cause error validations in the backend, #ToDo check why of this
    delete data.__v;

    auth
      .editHouse(data, houseBeingEdited._id)
      .then((res) => {
        setHouseBeingEdited(res.data);
        toast.success('House edited');
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <GCard header={'Edit House'}>
      <Form className="m-3" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridHouseName">
            <Form.Label className="text-bold">House Name</Form.Label>
            <Form.Control
              {...register('house_name')}
              name="house_name"
              placeholder="house Name"
              isValid={!errors.house_name}
              isInvalid={!!errors.house_name}
            />
            <Form.Control.Feedback type="invalid">{errors.house_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridAddress">
            <Form.Label className="text-bold">Address</Form.Label>
            <Form.Control
              {...register('address')}
              name="address"
              placeholder="Address"
              isValid={!errors.address}
              isInvalid={!!errors.address}
            />
            <Form.Control.Feedback type="invalid">{errors.address?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridPost">
            <Form.Label className="text-bold">Postal Code</Form.Label>
            <Form.Control
              {...register('postal_code')}
              name="postal_code"
              placeholder="Address"
              isValid={!errors.postal_code}
              isInvalid={!!errors.postal_code}
            />
            <Form.Control.Feedback type="invalid">{errors.postal_code?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridHouseCode">
            <Form.Label className="text-bold">House Code</Form.Label>
            <Form.Control
              {...register('house_code')}
              name="house_code"
              placeholder="House Code"
              isValid={!errors.house_code}
              isInvalid={!!errors.house_code}
            />
            <Form.Control.Feedback type="invalid">{errors.house_code?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-md-4 mb-3" controlId="formGridWIFI">
            <Form.Label className="text-bold">WIFI Name</Form.Label>
            <Form.Control
              {...register('wifi_name')}
              name="wifi_name"
              placeholder="WIFI Name"
              isValid={!errors.wifi_name}
              isInvalid={!!errors.wifi_name}
            />
            <Form.Control.Feedback type="invalid">{errors.wifi_name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridWIFIPASS">
            <Form.Label className="text-bold">WIFI Password</Form.Label>
            <Form.Control
              {...register('wifi_password')}
              name="wifi_password"
              placeholder="WIFI Password"
              isValid={!errors.wifi_password}
              isInvalid={!!errors.wifi_password}
            />
            <Form.Control.Feedback type="invalid">{errors.wifi_password?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridUtils">
            <Form.Label className="text-bold">Utilities</Form.Label>
            <Form.Control
              {...register('utilities')}
              name="utilities"
              type="number"
              step="any"
              placeholder="Utilities"
              isValid={!errors.utilities}
              isInvalid={!!errors.utilities}
            />
            <Form.Control.Feedback type="invalid">{errors.utilities?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-md-4 mb-3" controlId="formGridNumTen">
            <Form.Label className="text-bold">Number of Tenants</Form.Label>
            <Form.Control
              {...register('numTenants')}
              name="numTenants"
              type="number"
              step="any"
              placeholder="Utilities"
              isValid={!errors.numTenants}
              isInvalid={!!errors.numTenants}
            />
            <Form.Control.Feedback type="invalid">{errors.numTenants?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="col-lg mb-3 ms-3">
            <Form.Label className="row text-bold">Variable costs for house changes</Form.Label>
            <Button className="row" variant="warning" onClick={() => goToAddVariableCosts()}>
              Go to variable costs
            </Button>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group className="col-md-4 mb-3" controlId="formGridUtils">
            <Form.Label className="text-bold">Homeowner Name</Form.Label>
            <Form.Control
              {...register('lead')}
              name="lead"
              step="any"
              value={houseBeingEdited?.lead}
              placeholder="Landlord Name"
              isValid={!errors.lead}
              isInvalid={!!errors.lead}
            />
            <Form.Control.Feedback type="invalid">{errors.lead?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-md-4 mb-3" controlId="formGridNumTen">
            <Form.Label className="text-bold">Homeowner Sign</Form.Label>
            <Form.Control
              {...register('landlord_signature')}
              name="landlord_signature"
              step="any"
              value={houseBeingEdited?.landlord_signature}
              placeholder="Landlord Signature"
              isValid={!errors.landlord_signature}
              isInvalid={!!errors.landlord_signature}
            />
            <Form.Control.Feedback type="invalid">{errors.landlord_signature?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="col-lg mb-3 ms-3">
            <Form.Label className="row text-bold">Report of damage deposit</Form.Label>
            <DamageDepositReportButton houseSelected={houseBeingEdited} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group className="mb-3" controlId="formGridWelcome">
            <Form.Label className="text-bold">Welcome Message</Form.Label>
            <Form.Control
              {...register('welcome_message')}
              name="welcome_message"
              as="textarea"
              placeholder="WIFI Password"
              isValid={!errors.welcome_message}
              isInvalid={!!errors.welcome_message}
            />
            <Form.Control.Feedback type="invalid">{errors.welcome_message?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="raw-with-margin mb-4">
          <Form.Group className="col-lg-12 mx-auto" controlId="formGridNumBathrooms">
            <Form.Label>Number of Bathrooms</Form.Label>
            <Form.Control
              onChange={(e) => {
                const newNumber = Number(e.target.value);
                if (newNumber < bathrooms.length) {
                  setBathrooms((existing) => existing.slice(0, newNumber));
                }
                setNumberOfBathrooms(newNumber);
              }}
              value={numberOfBathrooms}
            />
          </Form.Group>
        </Row>
        {new Array(Number(numberOfBathrooms)).fill('').map((_, index) => {
          const bathroom = bathrooms[index];
          return (
            <Row key={index} className="mb-4">
              <Form.Group className="col-8" controlId={`bathroom${index}Form`}>
                <Form.Label>Bathroom #{index + 1} short name</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    const newName = e.target.value;
                    const newVal = [...bathrooms];
                    const existingBathroom = newVal[index];
                    if (existingBathroom != null) {
                      existingBathroom.name = newName;
                    } else {
                      newVal[index] = { name: newName, roomies: [] };
                    }
                    setBathrooms(newVal);
                  }}
                  value={bathroom?.name}
                  placeholder="Down stairs to the left"
                />
              </Form.Group>
              <Form.Group controlId={`bathroom${index}LinkToRoom`}>
                <Form.Label>Rooms that use this bathroom</Form.Label>
                <Select
                  classNamePrefix="addl-class"
                  options={roomiesList.map((room) => ({ value: room._id, label: room.room_name }))}
                  isMulti
                  onChange={(val) => {
                    const newSelection = val;
                    const newVal = [...bathrooms];
                    const existingBathroom = newVal[index];
                    if (existingBathroom != null) {
                      existingBathroom.roomies = newSelection.map(({ value }) => value);
                    } else {
                      newVal[index] = { name: '', roomies: newSelection.map(({ value }) => value) };
                    }
                    setBathrooms(newVal);
                  }}
                  value={bathroom?.roomies.map((roomId) => ({
                    value: roomId,
                    label: roomiesList.find((room) => room._id === roomId)?.room_name ?? '',
                  }))}
                />
              </Form.Group>
            </Row>
          );
        })}
        <Row className="mb-5">
          {choresList &&
            roomiesList.map((roomie) => (
              <div className=" col-md-4 " key={roomie._id}>
                <Form.Group controlId="formGridChores">
                  <Form.Label className="text-bold">Chores for room: {roomie.room_name}</Form.Label>
                  <Controller
                    control={control}
                    name={`selected_chores.${roomie._id}`}
                    render={({ field: { onChange, ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={transform2(choresList)}
                        isMulti
                        onChange={(val) => onChange(val.map((c) => c.value))}
                        defaultValue={transformChores(getChoreList()).filter((chore) => chore.who === roomie._id)}
                      />
                    )}
                  />
                </Form.Group>
              </div>
            ))}
        </Row>
        <Row className="mb-3">
          <Form.Group className="mb-3" controlId="formShouldRotate">
            <Form.Label className="text-bold">Should rotate the chores automatically?</Form.Label>
            <Form.Switch {...register('should_rotate')} name="should_rotate" />
          </Form.Group>
        </Row>
        <div className="text-center">
          <div className="row">
            <Button variant="info" className="col ms-2 me-2" type="submit">
              {'Save'}
            </Button>

            <Button variant="secondary" className="col ms-2 me-2" onClick={() => history(-1)}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </GCard>
  );
}

export default EditHouse;
