import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import auth from '../../services/authService';
import { useRooms } from '../../redux/adminSlice';

function AdminSuppliesPage() {
  const tenants = useRooms();
  const [supplies, setSupplies] = useState(tenants);
  let history = useNavigate();

  const onVerify = (rId, sId, price) => {
    auth
      .verifySupply(rId, sId, price)
      .then(() => {
        toast.success('Verified');
      })
      .catch(() => toast.error('Something Wrong Happened'))
      .then(() => removeSupply(rId, sId));
  };

  const onDelete = (rId, sId) => {
    auth.deleteSupply(rId, sId).then(removeSupply(rId, sId));
  };

  const removeSupply = (rId, sId) => {
    setSupplies((sups) => {
      sups.forEach((feed) => {
        if (feed._id === rId) {
          feed.supplies.supplies_items.forEach((element, i) => {
            if (element._id === sId) {
              delete feed.supplies.supplies_items[i];
            }
          });
        }
      });
      history({ state: sups }, { replace: true });
      return [...sups];
    });
  };

  const getSupplies = () => {
    return supplies.filter((filtered) => {
      let result = false;
      filtered.supplies.supplies_items.forEach((verified) => {
        if (verified.verified === 0) result = true;
      });
      return result;
    });
  };

  return (
    <div className="card table-responsive p-0 ">
      <table className="table align-items-center  mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>House</th>
          </tr>
        </thead>
        <tbody>
          {getSupplies().length ? (
            getSupplies().map((roomie) => (
              <tr key={roomie._id}>
                <td>{roomie.room_name}</td>
                <td>{roomie.house.house_name}</td>
                <td colSpan="5">
                  <div className="card table-responsive p-0 ">
                    <table className="table mb-2">
                      <thead className="bg-dark">
                        <tr>
                          <th className="text-white">Date</th>
                          <th className="text-white">What?</th>
                          <th className="text-white">Price</th>
                          <th className="text-white">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {roomie.supplies.supplies_items
                          .filter((filtered) => filtered.verified === 0)
                          .map((supply, i) => (
                            <tr key={i}>
                              <td>{new Date(supply.date).toDateString()}</td>
                              <td className="text-wrap">{supply.what}</td>
                              <td>{supply.price}</td>
                              <td>
                                <div>
                                  <ThumbUpAltIcon
                                    role="button"
                                    className="mr-2 mx-4 text-info"
                                    onClick={() => onVerify(roomie._id, supply._id, supply.price)}
                                  />

                                  <DeleteForeverIcon
                                    role="button"
                                    className="text-danger enable-button-pointers"
                                    onClick={() => onDelete(roomie._id, supply._id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>
                <h3>No supplies to verify</h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AdminSuppliesPage;
