import React from 'react';
import { useStateValue } from '../../context/StateProvider';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import auth from '../../services/authService';
import { useRooms } from '../../redux/adminSlice';

function AgreementModal({ open, setOpen, agreement }) {
  const { register, handleSubmit, reset, setValue, watch } = useForm();

  const housesWatch = watch('house');
  const [{ user }] = useStateValue();
  const tenantsList = useRooms();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    data.agreement = {
      ...agreement,
      agreement_id: agreement._id,
    };
    delete data.agreement._id;
    await auth.addAgreementToRoomie(data);
    handleClose();
  };

  return (
    <Modal show={open} onHide={handleClose} backdrop="static" keyboard={false} className="fade">
      <Modal.Header closeButton>
        <Modal.Title>Assign an agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Select a house first and then a room to assign an agreement to a room
        <Form className="m-4" onSubmit={handleSubmit(onSubmit)} onReset={reset} id="myform">
          <Row className="mb-3">
            <Form.Group className="col mb-3" controlId="formGridType">
              <Form.Label className="text-bold">Select a House</Form.Label>
              <Form.Control
                className="bg-light"
                // defaultValue={!isAddMode ? editRoomie.house._id : null}
                // value={!isAddMode ? editRoomie.house._id : null}
                {...register('house')}
                name="house"
                as="select"
                onChange={(e) => setValue('house', e.target.value)}
              >
                <option value={-1}>Select...</option>
                {user.houses.map((house, i) => (
                  <option value={house._id} key={'house-' + i}>
                    {house.house_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group className="col mb-3" controlId="formGridType">
              <Form.Label className="text-bold">Select Room</Form.Label>
              <Form.Control
                className="bg-light"
                // defaultValue={!isAddMode ? editRoomie.house._id : null}
                // value={!isAddMode ? editRoomie.house._id : null}
                {...register('room')}
                name="room"
                as="select"
                onChange={(e) => setValue('room', e.target.value)}
              >
                <option value={-1}>Select...</option>
                {!housesWatch
                  ? ''
                  : tenantsList
                      .filter((x) => x.house._id === housesWatch)
                      .map((room, i) => (
                        <option value={room._id} key={i}>
                          {room.room_name}
                        </option>
                      ))}
              </Form.Control>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="myform">
          Add Agreement to Room
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AgreementModal;
