import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import GCard from '../../utils/GCard';
import { useForm } from 'react-hook-form';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import auth from '../../../services/authService';
import { toast } from 'react-toastify';
import { useHouses } from '../../../redux/adminSlice';

const lastMonthDate = dayjs();
const currentYearDate = dayjs().subtract(0, 'year');
const maxYearDate = dayjs().add(10, 'year');
const minYearDate = dayjs().subtract(20, 'year');

function AddVariableCosts() {
  let history = useNavigate();
  const { id: houseId } = useParams();
  const variableCostsInputRef = useRef([]);
  const variableCostsTempValues = useRef([]);
  const toastHasRecentlyTriggered = useRef(false);
  const [, rerender] = useState(); //Used to rerender the dynamic form
  const [loading, setLoading] = useState(true);
  const [housesList, setHousesList] = useState(null);
  const [monthSelected, setMonthSelected] = useState(lastMonthDate);
  const [yearSelected, setYearSelected] = useState(currentYearDate);
  const [inputNewVariableCost, setInputNewVariableCost] = useState('');
  const [costPerPerson, setCostPerPerson] = useState(0);
  const [totalVariableCosts, setTotalVariableCosts] = useState(0);
  const housesListState = useHouses();

  const [roomiesCountInHouse, setRoomiesCountInHouse] = useState(0);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const getHouseData = useCallback(() => {
    const houseListData = housesList || housesListState;
    return houseListData.find((val) => val._id === houseId);
  }, [houseId, housesList, housesListState]);

  const getVariableCostsFromState = useCallback(() => {
    const houseData = getHouseData();
    return houseData.variable_costs.find(
      (vc) => vc.year === yearSelected.format('YYYY') && vc.month === monthSelected.format('MMMM')
    );
  }, [getHouseData, yearSelected, monthSelected]);

  const calculateCostPerPerson = useCallback(
    (roomiesFromState = 0) => {
      if (variableCostsTempValues.current && (roomiesCountInHouse || roomiesFromState)) {
        const utilitiesCost = variableCostsTempValues.current.reduce((prev, current) => {
          return Number(prev) + Number(current.costValue);
        }, 0);
        const roomiesCount = +roomiesCountInHouse || +roomiesFromState;
        setCostPerPerson(Math.round(utilitiesCost / roomiesCount));
        setTotalVariableCosts(utilitiesCost);
      }
    },
    [roomiesCountInHouse]
  );

  useEffect(() => {
    setLoading(true);
    setHousesList(housesListState);
    async function fetchRoomieFromHouseList() {
      await auth.getRoomieFromHouse(houseId).then((res) => {
        setRoomiesCountInHouse(() => {
          const roomiesCount = res.data.length;
          calculateCostPerPerson(roomiesCount);
          return roomiesCount;
        });
      });
    }
    fetchRoomieFromHouseList();
    const variableCostsFromState = getVariableCostsFromState();
    if (variableCostsFromState) {
      const variableCostInState = variableCostsFromState.costs;
      variableCostsTempValues.current = variableCostInState;
      calculateCostPerPerson();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    async function fetchHouseList() {
      await auth.getOwnerHouses(housesListState).then((res) => {
        setHousesList(res.data);
      });
    }
    fetchHouseList();
    const variableCostsFromState = getVariableCostsFromState();
    if (variableCostsFromState) {
      const variableCostInState = variableCostsFromState.costs;
      variableCostsTempValues.current = variableCostInState;
      calculateCostPerPerson();
      rerender(Symbol());
    } else {
      variableCostsTempValues.current = [];
      calculateCostPerPerson();
      if (!toastHasRecentlyTriggered.current) {
        toast.warning(
          `There are not previous variable costs for ${monthSelected.format('MMMM')} ${yearSelected.format('YYYY')}.`
        );
        setTimeout(() => {
          toastHasRecentlyTriggered.current = false;
        }, 1000);
      }
      toastHasRecentlyTriggered.current = true;

      rerender(Symbol());
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthSelected, yearSelected]);

  const removeVariableCost = useCallback((idxToRemove) => {
    const newVariableCosts = variableCostsTempValues.current.filter((_, idx) => idx !== idxToRemove);
    variableCostsTempValues.current = newVariableCosts;
    rerender(Symbol());
  }, []);

  let calcCPPTimeout;
  const handleVariableCosts = useCallback(
    (event, inputIdx, costProp) => {
      clearTimeout(calcCPPTimeout);
      const newValue = event.target.value;

      if (variableCostsInputRef.current[inputIdx]) {
        variableCostsInputRef.current[inputIdx].value = newValue;
      }

      const newVariableCosts = [...variableCostsTempValues.current];
      newVariableCosts[inputIdx][costProp] = newValue;
      variableCostsTempValues.current = newVariableCosts;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      calcCPPTimeout = setTimeout(() => {
        //Should wait for the eventloop to re-render the dynamic form
        calculateCostPerPerson();
      }, 1500);
    },
    [calculateCostPerPerson]
  );

  const handleInputNewVariableCost = (event) => {
    const newVaribleCost = event.target.value;
    setInputNewVariableCost(newVaribleCost);
  };

  const addNewVariableCost = useCallback(() => {
    if (!inputNewVariableCost) {
      toast.error('Please specify a variable cost name!');
      return;
    }
    if (variableCostsTempValues.current) {
      const newVariableCostInputValue = inputNewVariableCost;
      if (variableCostsTempValues.current.find((vc) => vc.costName === newVariableCostInputValue)) {
        toast.error('This variable costs already exists!');
        setInputNewVariableCost('');
        return;
      }

      const newVariableCost = [
        ...variableCostsTempValues.current,
        {
          costName: newVariableCostInputValue,
          costValue: 0,
        },
      ];
      variableCostsTempValues.current = newVariableCost;
      setTimeout(() => {
        //Should wait for the eventloop to re-render the dynamic form
        setInputNewVariableCost('');
      }, 100);
    }
  }, [inputNewVariableCost]);

  function onSubmit() {
    const dataToSave = {
      variable_costs: {
        year: yearSelected.format('YYYY'),
        month: monthSelected.format('MMMM'),
        costs: variableCostsTempValues.current,
        costPerPerson,
        baseRent: 0,
      },
    };

    auth
      .addVariableCosts(dataToSave, houseId)
      .then(() => {
        toast.success('Variable costs added!');
      })
      .catch((err) => console.log(err.message));
  }

  const VaribleCostsComponent = useCallback(() => {
    if (!variableCostsTempValues.current) {
      return;
    }
    return variableCostsTempValues.current.map((vc, i) => (
      <Row key={`vc-${vc.costName}-${vc.costValue}-${i}`}>
        <Form.Group as={Row} className="mb-1" controlId="formGridUtils">
          <Col md="2">
            <Form.Label column>
              <span className="text-bold">Variable cost</span>
            </Form.Label>
          </Col>
          <Col md="4">
            <Form.Control
              ref={(el) => (variableCostsInputRef.current[`vcn.${vc.costName}-${i}`] = el)}
              name={`vcn.${vc.costName}-${i}`}
              type="text"
              defaultValue={vc.costName}
              placeholder="Variable cost name"
              onChange={(event) => handleVariableCosts(event, i, 'costName')}
            />
            <Form.Control.Feedback type="invalid">{errors[`vcn.${vc.costName}`]?.message}</Form.Control.Feedback>
          </Col>
          <Col md="4">
            <Form.Control
              ref={(el) => (variableCostsInputRef.current[`vcv.${vc.costName}-${i}`] = el)}
              name={`vcv.${vc.costName}-${i}`}
              type="number"
              defaultValue={vc.costValue}
              min="0"
              placeholder="Variable cost value"
              onChange={(event) => handleVariableCosts(event, i, 'costValue')}
            />
            <Form.Control.Feedback type="invalid">{errors[`vcv.${vc.costName}`]?.message}</Form.Control.Feedback>
          </Col>
          <Col md="2">
            <Button variant="secondary" onClick={() => removeVariableCost(i)}>
              Remove
            </Button>
          </Col>
        </Form.Group>
      </Row>
    ));
  }, [errors, handleVariableCosts, removeVariableCost]);

  const MemoizedVariableCostComponent = useMemo(() => VaribleCostsComponent, [VaribleCostsComponent]);

  return (
    <GCard header={`Add Variable Costs to ${getHouseData().house_name}`}>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'}>
          <div className="spinner-border col-md-1" role="status" />
        </Box>
      ) : (
        <>
          <Box display={'flex'} justifyContent={'start'} marginBlockEnd={2} marginLeft={2}>
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Select the Year:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'"year"'}
                  views={['year']}
                  defaultValue={yearSelected}
                  maxDate={dayjs(maxYearDate)}
                  minDate={dayjs(minYearDate)}
                  onChange={(value) => {
                    setYearSelected(value);
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                  sx={{ width: 120 }}
                />
              </LocalizationProvider>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Select the Month:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'"month"'}
                  views={['month']}
                  defaultValue={dayjs(monthSelected)}
                  onChange={(value) => {
                    setMonthSelected(value);
                  }}
                  slotProps={{ textField: { size: 'small' } }}
                  sx={{ width: 120 }}
                />
              </LocalizationProvider>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Roomies: {roomiesCountInHouse}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Cost per person: ${costPerPerson}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} marginRight={4}>
              <Typography variant="h6" component="h6" marginRight={2}>
                Total: ${totalVariableCosts}
              </Typography>
            </Box>
          </Box>
          <Form className="m-3 col-md-12" onSubmit={handleSubmit(onSubmit)} id="form_variable_costs">
            <Box className="col-md-11 mb-3">
              <Typography
                variant="subtitle1"
                component="em"
                marginRight={2}
                sx={{ cursor: 'pointer', '&:hover': { color: '#e28742', fontWeight: 'bold' } }}
              >
                WHAT ARE YOUR VARIABLE COSTS FOR HOUSE? For example, electricity changes each month, natural gas
                changes, even wifi costs can change, please list and we will divide by number of roomies.
              </Typography>
            </Box>
            <MemoizedVariableCostComponent />
          </Form>
          <Row className="col-md-12 m-1">
            <Col md="4">
              <Form.Control
                name={`newvariablecost`}
                type="text"
                value={inputNewVariableCost}
                placeholder="Put the variable cost name"
                onChange={handleInputNewVariableCost}
              />
            </Col>
            <Button className="col-md-2" variant="primary" onClick={() => addNewVariableCost()}>
              Add cost +
            </Button>
          </Row>
          <Button variant="primary" className="m-4" type="submit" form="form_variable_costs">
            Save
          </Button>
          <Button variant="danger" className="m-4" onClick={() => history(-1)}>
            Return to edit house
          </Button>
        </>
      )}
    </GCard>
  );
}

export default AddVariableCosts;
