import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import usePagination from '../../hooks/usePagination';
import auth from '../../services/authService';

function ArchiveView() {
  let roomId = useLocation().search.slice(4);

  const [roomState, setRoomState] = useState(null);

  useEffect(() => {
    async function fetchRoomie() {
      const res = await auth.getRoomieById(roomId);
      setRoomState(res.data);
    }
    fetchRoomie();
  }, [roomId]);
  const { Pagination, displayData } = usePagination({
    data: roomState.feedbacks,
    amountPerPage: 5,
  });

  const displayFeeds = displayData?.map((feed, i) => (
    <tr key={i}>
      <td>{feed.date}</td>
      <td>{feed.chore}</td>
      <td className="text-wrap">{feed.feedback}</td>
    </tr>
  ));

  const displayComments = roomState.admin_com.split('**').map((com, i) => (
    <tr key={i}>
      <td>{com}</td>
    </tr>
  ));
  if (roomState == null) return <>Loading...</>;
  return (
    <div>
      {!roomState.is_empty ? (
        <div className="card-body p-4">
          <div className="card bg-gray-200">
            <div className="row gx-3 mb-2 mx-0">
              <div className="col-auto my-3">
                <img
                  src={roomState.url}
                  alt=""
                  className="avatar avatar-md rounded-circle border-radius-md shadow-md position-relative"
                />
              </div>
              <div className="col-auto my-auto">
                <div className="h-100">
                  <h6 className="mb-1">{roomState.first_name + ' ' + roomState.last_name}</h6>
                  <p className="mb-0 font-weight-normal text-xs">Old Roomate</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                <div className="nav-wrapper position-absolute end-0"></div>
              </div>
            </div>

            {/* <h6 className="mb-3 text-sm">hello</h6> */}

            <div className="row">
              <div className="col-auto">
                <div className="d-flex flex-column mt-0 mb-md-4 ms-4 me-4">
                  <span className="mb-2 text-xs">
                    Email Address
                    <span className="text-dark font-weight-bold ms-sm-2">{roomState.email}</span>
                  </span>

                  <span className="mb-2 text-xs">
                    Phone Number
                    <span className="text-dark font-weight-bold ms-sm-2">{roomState.phone}</span>
                  </span>

                  <span className="mb-2 text-xs">
                    Last Base Rent
                    <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.base_rent}</span>
                  </span>

                  <span className="mb-2 text-xs">
                    Co-OP Deposit Paid
                    <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.coop_fee}</span>
                  </span>

                  <span className="mb-2 text-xs">
                    Damage Deposit Paid
                    <span className="text-dark font-weight-bold ms-sm-2">{'$' + roomState.deposit}</span>
                  </span>
                </div>
              </div>

              <div className="col-auto">
                <div className="d-flex flex-column mt-0 m-4">
                  <span className="mb-2 text-xs">
                    Last supplies paid:
                    <span className="text-dark font-weight-bold ms-sm-2">
                      {' $' + roomState.supplies.anchored_total}
                    </span>
                  </span>

                  <span className="mb-2 text-xs">
                    Total Rent Due:
                    <span className="text-dark font-weight-bold ms-sm-2">{' $' + roomState.rents.rent_list_total}</span>
                  </span>

                  <span className="mb-2 text-xs">
                    Total Money Payed:
                    <span className="text-dark font-weight-bold ms-sm-2">
                      {' $' + roomState.payments.payments_total}
                    </span>
                  </span>

                  <span className="mb-2 text-xs">
                    Total Past Charges Due:
                    <span className="text-dark font-weight-bold ms-sm-2">{' $' + roomState.past_due}</span>
                  </span>
                </div>
              </div>

              <div className="col-auto"></div>
            </div>

            <div className="card m-3">
              <div className="table-responsive">
                <table className="table align-items-center mb-0 table-striped">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">
                        Admin Comments
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayComments}</tbody>
                </table>
              </div>
            </div>

            <div className="card m-3">
              <div className="table-responsive">
                {Pagination}{' '}
                <table className="table align-items-center mb-0 table-striped">
                  <thead>
                    <tr>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7">Date</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 p-2">Chore</th>
                      <th className="text-uppercase text-secondary text-sm font-weight-bolder opacity-7 p-2">
                        Feedback
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayFeeds}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ArchiveView;
