import React from 'react';
import VideoHeader from './VideoHeader';
import Footer from './Footer';
import ButtonAppBar from './ButtonAppBar';
import { Swiper, SwiperSlide } from 'swiper/react';
import useFetchOwnersData from '../hooks/useFetchOwnersData';
import PlaceIcon from '@mui/icons-material/Place';
import { Link } from 'react-router-dom';
import { EffectCoverflow, Pagination } from 'swiper';

import 'swiper/swiper-bundle.min.css'; // core Swiper
import 'swiper/css/effect-cards'; // Navigation module
import '../css/style1.css';

function scale_url(url) {
  const url_split = url.split('/upload');
  return `${url_split[0]}/upload/c_scale,h_511,w_681/${url_split[1]}`;
}

function Landing2() {
  const [houses, isHousesReady] = useFetchOwnersData('empty_rooms');

  return (
    <div className="presentation-page flex bg-dark">
      <ButtonAppBar />

      <div className="">
        <section className="page-header min-vh-75 relative">
          <VideoHeader />
        </section>

        <section className=" card card-body blur shadow-blur mx-3 mx-md-4 mt-n10">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            autoHeight={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination]}
            className="hello"
          >
            {isHousesReady
              ? houses.map((house, i) => (
                  <SwiperSlide key={i} className="hello-slide">
                    <div className="mb-4">
                      <div className="card mt-5 bg-light " data-animation="true">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                          <span className="d-block blur-shadow-image">
                            <img
                              src={scale_url(house.image_array[0].url)}
                              alt="img-blur-shadow"
                              className="img-fluid shadow border-radius-lg"
                            />
                          </span>
                        </div>
                        <div className="card-body pb-0">
                          <div className="mt-n6">
                            <div className="text-primary border-1 text-center">${house.base_rent}</div>
                            <div className="text-xs text-muted text-center mt-n1">+ utilities</div>
                          </div>
                          <h5 className="font-weight-normal text-uppercase text-case text-center mt-3">
                            <span>{house.room_id.room_name}</span>
                          </h5>
                          <hr className="bg-dark horizontal my-0" />
                          <div className="pt-3 mb-0">
                            <div className="d-flex flex-column mt-0 ms-4 me-4">
                              <Link
                                className="btn btn-secondary border rounded-pill"
                                role="button"
                                to={`/roomad?id=${house._id}`}
                              >
                                More INfo
                              </Link>
                            </div>
                          </div>
                        </div>
                        <hr className="bg-dark horizontal my-0" />
                        <div className="card-footer d-flex justify-content-center">
                          <PlaceIcon className="position-relative text-lg text-dark me-2" />
                          <p className="text-sm text-bold text-dark my-auto">{house.house?.address}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Landing2;
