import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";

function EmptyRoomTable({ rooms }) {
  return (
    <div className="table-responsive p-0">
      <table className="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Room
            </th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Base Rent
            </th>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-3">
              House
            </th>
            <th className=""></th>
          </tr>
        </thead>

        <tbody>
          {rooms.map((room, i) => (
            <tr key={i}>
              <td>
                <div className="d-flex px-2">
                  <div>
                    <img
                      src={room.url}
                      className="avatar avatar-sm me-3 border-radius-lg"
                      alt=""
                    />
                  </div>
                  <div className="my-auto">
                    <h6 className="mb-0 text-sm">{room.room_name}</h6>
                  </div>
                </div>
              </td>
              <td className="align-middle">
                <p className="text-sm font-weight-bold mb-0">
                  {"$" + room.base_rent}
                </p>
              </td>
              <td>
                <div className="d-flex px-2 py-1">
                  <div>
                    <img
                      src={room.house.url}
                      className="avatar avatar-sm me-3 border-radius-lg"
                      alt=""
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h6 className="mb-0 text-sm">{room.house.house_name}</h6>
                    <p className="text-xs text-secondary mb-0">
                      {room.house.address}
                    </p>
                  </div>
                </div>
              </td>

              <td className="align-middle">
                <Link to={`/view?id=${room._id}`}>
                  <SearchIcon className="text-secondary font-weight-bold" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default EmptyRoomTable;
